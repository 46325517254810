import React from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { formatNumber } from '@tager/web-core';

import InfoIcon from '@/assets/svg/info.svg';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';

import Content from './components/Content';
import { PriceInfoProps } from './types';

function PriceInfo({
  title,
  price,
  infoType,
  infoText,
  link,
  isBig,
  isMobile,
}: PriceInfoProps) {
  return (
    <Component>
      <Header>
        <Label isBig={isBig} isMobile={isMobile}>
          {title}
          {link && <StyledLink to={link} />}
        </Label>
        <Tippy content={<Content infoText={infoText} />} placement="bottom">
          <Icon isBig={isBig} isMobile={isMobile}>
            <InfoIcon />
          </Icon>
        </Tippy>
      </Header>
      <Price isBig={isBig} infoType={infoType} isMobile={isMobile}>
        {infoType === 'economy' ? (
          formatNumber(Math.round(price))
        ) : (
          <>
            <Prefix isBig={isBig} isMobile={isMobile}>
              от
            </Prefix>{' '}
            {formatNumber(Math.round(price))}
          </>
        )}
        {link && <StyledLink to={link} />}
      </Price>
    </Component>
  );
}

export default PriceInfo;

export const Component = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span<{ isMobile?: boolean; isBig?: boolean }>`
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.black};

  ${({ isBig }) =>
    isBig &&
    css`
      font-size: 16px;
      line-height: 19px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-weight: 600;
      font-size: 9px;
      line-height: 11px;
    `}
`;

export const Icon = styled.span<{ isBig?: boolean; isMobile?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 11px;
  height: 11px;
  margin-left: 3px;
  margin-bottom: 2px;

  svg {
    path {
      &:first-child {
        stroke: ${(props) => props.theme.black};
      }

      &:not(:first-child) {
        fill: ${(props) => props.theme.blue100};
      }
    }
  }

  ${({ isBig }) =>
    isBig &&
    css`
      margin-bottom: 0;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 9px;
      height: 9px;
      margin-left: 2px;
    `}
`;

export const Price = styled.div<{
  infoType: PriceInfoProps['infoType'];
  isBig?: boolean;
  isMobile?: boolean;
}>`
  position: relative;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  white-space: nowrap;

  ${({ isBig }) =>
    isBig &&
    css`
      font-size: 32px;
      line-height: 38px;
    `}

  ${({ infoType }) =>
    infoType === 'economy'
      ? css`
          color: #e7ab00;
        `
      : css`
          color: ${colors.main};
        `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 15px;
      line-height: 18px;
    `}
`;

export const Prefix = styled.span<{ isMobile?: boolean; isBig?: boolean }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.black100};

  ${({ isBig }) =>
    isBig &&
    css`
      font-size: 18px;
      line-height: 21px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 9px;
      line-height: 11px;
    `}
`;

const StyledLink = styled(Link)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;
