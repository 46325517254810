import React from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { colors } from '@/constants/theme';

import Checkbox from './components/Checkbox';
import { CompareCheckboxProps } from './types';

function CompareCheckbox({
  className,
  checked,
  onChange,
  isLaptop,
  isMobile,
}: CompareCheckboxProps) {
  return (
    <Component className={className} isLaptop={isLaptop} isMobile={isMobile}>
      <Tippy
        content={
          <Info>{!checked ? 'В сравнение' : 'Удалить из сравнения'}</Info>
        }
        placement="bottom-start"
        zIndex={100}
      >
        <CheckBoxWrapper>
          <Checkbox checked={checked} onChange={onChange} />
        </CheckBoxWrapper>
      </Tippy>
    </Component>
  );
}

export default CompareCheckbox;

const Component = styled.div<{ isLaptop?: boolean; isMobile?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  background: ${colors.white};
  border-radius: 6px;

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      width: 25px;
      height: 25px;

      & > div > div {
        width: 13px;
        height: 13px;

        svg {
          width: 13px;
          height: 13px;
        }
      }
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 20px;
      height: 20px;

      & > div > div {
        width: 10px;
        height: 10px;

        svg {
          width: 10px;
          height: 10px;
        }
      }
    `}
`;

const CheckBoxWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  padding: 10px;
  box-shadow: 0 4px 19px rgb(21 27 38 / 13%);
  border: 0.5px solid ${colors.gray400};
  border-radius: 8px;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  background: ${colors.white};
`;
