import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import Link from '@/components/Link';
import CameraIcon from '@/assets/svg/camera.svg';
import CompareCheckbox from '@/components/CompareCheckbox';
import CurrentStateCar from '@/components/StockCarCard/components/CurrentStateCar';
import { colors } from '@/constants/theme';
import EyeIcon from '@/assets/svg/detailed-car/eye.svg';
import CarApproved from '@/components/CarApproved';
import StockStatus from '@/components/StockCarCard/components/StockStatus';

import { StockCardSliderProps } from './types';

function StockCardSlider({
  imageFit,
  images,
  view,
  link,
  totalImagesCount,
  isCompared,
  onCompareChange,
  carStatus,
  stockStatus,
  approved,
  countViews,
  alt,
  isLaptop,
}: StockCardSliderProps) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const slides = images.map((item, index) => {
    return (
      <SlideListItem
        key={index}
        onMouseEnter={() => {
          setActiveSlideIndex(index);
        }}
        isActive={activeSlideIndex === index}
      >
        <StyledPicture
          imageFit={imageFit}
          src={item}
          loading="lazy"
          alt={`${alt ?? 'stock card image'} #${index + 1}`}
        />
      </SlideListItem>
    );
  });

  return (
    <Component
      view={view}
      onMouseOut={() => {
        setActiveSlideIndex(0);
      }}
      isLaptop={isLaptop}
    >
      {link ? (
        <SlideListLink to={link}>{slides}</SlideListLink>
      ) : (
        <SlideList>{slides}</SlideList>
      )}

      {images.length > 1 ? (
        <PaginationList>
          {images.map((item, index) => {
            return (
              <PaginationListItem
                key={index}
                isActive={activeSlideIndex === index}
                isLaptop={isLaptop}
              />
            );
          })}
        </PaginationList>
      ) : null}

      {totalImagesCount &&
      activeSlideIndex === images.length - 1 &&
      totalImagesCount - images.length ? (
        <MorePhotosSlide>
          <CameraIcon />
          <MorePhotosTitle>
            +{totalImagesCount - images.length} фото
          </MorePhotosTitle>
        </MorePhotosSlide>
      ) : null}

      {onCompareChange && (
        <CheckboxWrapper>
          <CompareCheckbox
            checked={!!isCompared}
            onChange={onCompareChange}
            isLaptop={isLaptop}
          />
        </CheckboxWrapper>
      )}

      {carStatus && (
        <CurrentStateWrapper>
          <CurrentStateCar text={carStatus} />
        </CurrentStateWrapper>
      )}

      {stockStatus && (
        <StockStatusWrapper>
          <StockStatus carStatus={stockStatus} />
        </StockStatusWrapper>
      )}

      {approved && (
        <CarApprovedWrapper>
          <CarApproved />
        </CarApprovedWrapper>
      )}

      {countViews ? (
        <Views isLaptop={isLaptop}>
          <EyeIcon />
          {countViews}
        </Views>
      ) : null}
    </Component>
  );
}

export default StockCardSlider;

export const Component = styled.div<{
  view?: 'landscape' | 'portrait';
  isLaptop?: boolean;
}>`
  position: relative;
  background-color: #efefef;
  padding-top: 65.481%;

  ${({ view }) =>
    view === 'portrait'
      ? css`
          border-radius: 8px 8px 0 0;
        `
      : css`
          border-radius: 8px 0 0 8px;
        `}

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      padding-top: 77.47%;
    `}
`;

const SlideListStyles = css`
  z-index: 1;
  display: flex;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;

const SlideListLink = styled(Link)`
  ${SlideListStyles}
`;

const SlideList = styled.div`
  ${SlideListStyles}
`;

const SlideListItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  flex: 1;

  ${({ isActive }) =>
    isActive &&
    css`
      ${StyledPicture} {
        display: block;
        z-index: -1;
      }
    `}
`;

export const StyledPicture = styled(Picture)<{
  imageFit: 'contain' | 'cover';
}>`
  display: none;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0 0;
  }

  ${({ imageFit }) =>
    imageFit &&
    css`
      img {
        object-fit: ${imageFit};
      }
    `}
`;

const PaginationList = styled.div`
  z-index: 1;
  display: flex;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`;

const PaginationListItem = styled.div<{
  isActive: boolean;
  isLaptop?: boolean;
}>`
  width: 40px;
  height: 4px;
  background-color: ${colors.black};
  border-radius: 3px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${colors.white};
    `}

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      width: 35px;
    `}
`;

const MorePhotosSlide = styled.div`
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
`;

export const MorePhotosTitle = styled.h2`
  margin-top: 20px;
  color: ${colors.white};
`;

const CheckboxWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 5px;
  left: 5px;
`;

const CurrentStateWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: 5px;
  bottom: -17.5px;
`;

const StockStatusWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 5px;
  right: 5px;
`;

const Views = styled.div<{ isLaptop?: boolean }>`
  z-index: 1;
  position: absolute;
  left: 20px;
  bottom: -16px;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 16px;
  background: ${colors.white};
  border: 0.5px solid rgba(21, 27, 38, 0.4);
  border-radius: 80px;

  svg {
    margin-right: 5px;
  }

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      padding: 7px 6px;
      height: 25px;
      font-size: 10px;
      line-height: 12px;
      left: 15px;
      bottom: -12.5px;

      svg {
        width: 12px;
        height: 12px;
      }
    `}
`;

const CarApprovedWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(50%);
`;
