import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import VinFileIcon from '@/assets/svg/detailed-car/vin-file.svg';
import SuccessIcon from '@/assets/svg/success.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  vin: string;
}

function VINContainer({ vin }: Props) {
  const [isCopied, setIsCopied] = useState(false);

  function handleCopyBuffer() {
    window.navigator.clipboard.writeText(vin).then(() => setIsCopied(true));

    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  }

  return (
    <>
      <Container>
        <VINInfo>{vin}</VINInfo>
        {isCopied ? (
          <StyledSuccessIcon />
        ) : (
          <StyledVINFileIcon onClick={handleCopyBuffer} />
        )}
      </Container>
    </>
  );
}

export default VINContainer;

const Container = styled.div`
  position: relative;
  display: flex;
  padding: 15px;
  border-radius: 8px;
  border: 0.5px solid ${colors.gray400};
  background-color: ${colors.white};
  box-shadow: 0 4px 19px rgba(21, 27, 38, 0.13);
  align-items: center;

  ${media.mobile(css`
    flex-direction: row-reverse;
    padding: 18px 30px;
  `)}
`;

const VINInfo = styled.span`
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  padding-right: 16px;

  ${media.mobile(css`
    font-size: 14px;
    line-height: 16px;
    padding-right: 0;
  `)}
`;

const StyledVINFileIcon = styled(VinFileIcon)`
  cursor: pointer;
  width: 25px;
  height: 25px;

  path {
    fill: ${colors.main};
  }

  &:hover {
    transform: scale(1.1);
  }

  ${media.mobile(css`
    margin-right: 10px;
  `)}
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  width: 25px;
  height: 25px;

  ${media.mobile(css`
    margin-right: 10px;
  `)}
`;
