import React from 'react';
import styled from 'styled-components';

import { StockCarCardProps } from './types';
import CarPortraitView from './components/CarPortraitView';
import CarLandscapeView from './components/CarLandscapeView';

function StockCarCard({
  view,
  link,
  name,
  subName,
  buttonText,
  buttonHandle,
  images,
  imageFit,
  price,
  tags,
  badgesList,
  totalImagesCount,
  isCompared,
  onCompareChange,
  carStatus,
  stockStatus,
  approved,
  countViews,
  isLaptop,
  isMobile,
}: StockCarCardProps) {
  return (
    <Component>
      {view === 'portrait' ? (
        <CarPortraitView
          view={view}
          buttonText={buttonText}
          buttonHandle={buttonHandle}
          images={images}
          imageFit={imageFit}
          link={link}
          name={name}
          subName={subName}
          price={price}
          tags={tags}
          badgesList={badgesList}
          totalImagesCount={totalImagesCount}
          isCompared={isCompared}
          onCompareChange={onCompareChange}
          carStatus={carStatus}
          stockStatus={stockStatus}
          approved={approved}
          countViews={countViews}
          isLaptop={isLaptop}
          isMobile={isMobile}
        />
      ) : (
        <CarLandscapeView
          view={view}
          buttonText={buttonText}
          buttonHandle={buttonHandle}
          images={images}
          imageFit={imageFit}
          link={link}
          name={name}
          subName={subName}
          price={price}
          tags={tags}
          badgesList={badgesList}
          totalImagesCount={totalImagesCount}
          isCompared={isCompared}
          onCompareChange={onCompareChange}
          carStatus={carStatus}
          stockStatus={stockStatus}
          approved={approved}
        />
      )}
    </Component>
  );
}

export default StockCarCard;

const Component = styled.div`
  border-radius: 8px;
  border: 1px ${(props) => props.theme.gray500} solid;
  height: 100%;
`;
