export { default as Badge } from './Badge';
export { default as BadgeGuarantee } from './BadgeGuarantee';
export { default as BadgeOnlyOnline } from './BadgeOnlyOnline';
export { default as BadgeVat } from './BadgeVat';
export { default as BadgeNovelty } from './BadgeNovelty';
export { default as BadgeVideoReview } from './BadgeVideoReview';
export { default as BadgePurchaseOnline } from './BadgePurchaseOnline';
export { default as BadgeTestDrive } from './BadgeTestDrive';
export { default as BadgeLocation } from './BadgeLocation';
export { default as BadgePartnerCar } from './BadgePartnerCar';
