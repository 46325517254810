import React from 'react';
import styled, { css } from 'styled-components';

import ArrowRightIcon from '@/assets/svg/more_details_arrow_right.svg';
import Share from '@/components/Share';
import Link from '@/components/Link';
import StockCardSlider from '@/components/StockCarCard/components/StockCardSlider';
import {
  Component as StockCardSliderComponent,
  StyledPicture as StockCardSliderImage,
} from '@/components/StockCarCard/components/StockCardSlider/StockCardSlider';
import CarTags from '@/components/CarTags';
import BadgesList from '@/components/StockCarCard/components/BadgesList';
import CarPrice from '@/components/CarPrice';
import { colors } from '@/constants/theme';
import { Component as CarPriceComponent } from '@/components/CarPrice/CarPrice';

import { CarLandscapeViewProps } from './types';

function CarLandscapeView({
  view,
  link,
  name,
  buttonText = 'Получить предложение',
  buttonHandle,
  images,
  imageFit,
  price,
  subName,
  tags,
  badgesList,
  totalImagesCount,
  isCompared,
  onCompareChange,
  carStatus,
  stockStatus,
  approved,
}: CarLandscapeViewProps) {
  return (
    <Component>
      <CardSlider>
        <StockCardSlider
          images={images}
          imageFit={imageFit}
          alt={name}
          view={view}
          link={link}
          totalImagesCount={totalImagesCount}
          isCompared={isCompared}
          onCompareChange={onCompareChange}
          carStatus={carStatus}
          stockStatus={stockStatus}
          approved={approved}
        />
      </CardSlider>
      <CardInfo>
        <Left>
          <TitleWrapper>
            {link ? (
              <CardTitleLink to={link}>{name}</CardTitleLink>
            ) : (
              <CardTitle>{name}</CardTitle>
            )}

            {link && (
              <ShareWrapper>
                <Share path={link} image={images[0]} />
              </ShareWrapper>
            )}
          </TitleWrapper>

          {subName && <Subtitle>{subName}</Subtitle>}

          <CarTagsWrapper>
            <CarTags tags={tags} />
          </CarTagsWrapper>

          {badgesList && (
            <BadgesListWrapper>
              <BadgesList {...badgesList} />
            </BadgesListWrapper>
          )}

          {link && (
            <AboutModelWrapper>
              <AboutModel to={link}>
                Подробнее
                <Icon>
                  <ArrowRightIcon />
                </Icon>
              </AboutModel>
            </AboutModelWrapper>
          )}
        </Left>
        <Right>
          <CarPriceWrapper
            isFullPrice={!!(price.amount && price.discount && price.credit)}
          >
            <CarPrice {...price} isBig />
          </CarPriceWrapper>

          {buttonHandle && (
            <CarCardButton onClick={buttonHandle}>{buttonText}</CarCardButton>
          )}
        </Right>
      </CardInfo>
    </Component>
  );
}

export default CarLandscapeView;

const Component = styled.div`
  display: flex;
  width: 100%;
  border-radius: 8px;
`;

const CardInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 calc(100% - 21.763%);
  padding: 25px 50px;
  background: ${(props) => props.theme.white_1};
  border-radius: 0 8px 8px 0;
`;

const Left = styled.div`
  flex: 1 1 calc(100% - 41.107%);
  max-width: calc(100% - 41.107%);
`;

const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const TitleStyles = css`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${(props) => props.theme.black};
`;

const CardTitleLink = styled(Link)`
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  ${TitleStyles}

  &:hover {
    color: ${colors.main};
  }
`;

const CardTitle = styled.p`
  ${TitleStyles}
`;

const Subtitle = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-top: 5px;
  color: ${(props) => props.theme.black};
`;

const ShareWrapper = styled.div`
  margin-left: 25px;
`;

const CarTagsWrapper = styled.div`
  margin-top: 15px;
`;

const BadgesListWrapper = styled.div`
  margin-top: 10px;
`;

const Right = styled.div`
  flex: 1 1 41.107%;
  max-width: 41.107%;
  margin-left: 50px;
`;

const CarPriceWrapper = styled.div<{ isFullPrice?: boolean }>`
  ${({ isFullPrice }) =>
    isFullPrice &&
    css`
      ${CarPriceComponent} {
        justify-content: space-between;
      }
    `}
`;

const CardSlider = styled.div`
  position: relative;
  width: 100%;
  flex: 0 1 21.763%;

  ${StockCardSliderComponent} {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: initial;

    ${StockCardSliderImage} {
      picture,
      img {
        border-radius: 8px 0 0 8px;
      }
    }
  }
`;

const AboutModelWrapper = styled.div`
  display: flex;
  margin-top: 25px;
`;

const AboutModel = styled(Link)`
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.black};

  &:hover {
    color: ${colors.main};

    & div > svg {
      fill: ${colors.main};
    }
  }
`;

const Icon = styled.div`
  padding-top: 2px;
  margin-left: 5px;
  cursor: pointer;

  svg {
    fill: ${(props) => props.theme.black100};
  }
`;

const CarCardButton = styled.button`
  width: 100%;
  padding: 13px;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  border-radius: 20px;
  text-align: center;
  transition: background-color 0.1s ease-in-out;
  text-transform: uppercase;
  margin-top: 30px;
  background: ${colors.main};
  color: ${colors.white};

  &:hover {
    background-color: ${colors.main100};
  }
`;
