import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

import { ContentProps } from './types';

function Content({ infoText }: ContentProps) {
  return <Component>{infoText}</Component>;
}

export default Content;

const Component = styled.div`
  width: 280px;
  padding: 15px;

  background: ${colors.white};
  border: 0.5px solid ${colors.gray400};
  box-sizing: border-box;
  box-shadow: 0 4px 19px rgba(21, 27, 38, 0.13);
  border-radius: 8px;

  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  color: ${colors.black};
`;
