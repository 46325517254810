import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import TestDriveIcon from '@/assets/svg/test_drive.svg';

import Badge from '../Badge';

import { BadgeTestDriveProps } from './types';
import ContentTestDrive from './components/ContentTestDrive';

function BadgeTestDrive({
  label = 'Тест-драйв',
  isLaptop,
  isMobile,
}: BadgeTestDriveProps) {
  return (
    <Component>
      <Tippy zIndex={9998} content={<ContentTestDrive />} placement="bottom">
        <StyledBadge
          label={label}
          icon={<TestDriveIcon />}
          isLaptop={isLaptop}
          isMobile={isMobile}
        />
      </Tippy>
    </Component>
  );
}

export default BadgeTestDrive;

const StyledBadge = styled(Badge)`
  color: #008686;
  background-color: #d8fcfc;
`;

const Component = styled.div``;
