import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import VideoReviewIcon from '@/assets/svg/video_review.svg';

import Badge from '../Badge';

import Content from './components/Content';
import { BadgeVideoReviewProps } from './types';

function BadgeVideoReview({
  label = 'Видеообзор',
  video,
  isLaptop,
  isMobile,
}: BadgeVideoReviewProps) {
  return (
    <Tippy zIndex={9998} content={<Content video={video} />} placement="bottom">
      <StyledBadge
        label={label}
        icon={<VideoReviewIcon />}
        isLaptop={isLaptop}
        isMobile={isMobile}
      />
    </Tippy>
  );
}

export default BadgeVideoReview;

const StyledBadge = styled(Badge)`
  color: #e35b5b;
  background-color: #ffe7e7;
`;
