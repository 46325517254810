import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { CurrentStateCarProps } from './types';

function CurrentStateCar({ text }: CurrentStateCarProps) {
  if (!text) {
    return null;
  }

  return <Component>{text}</Component>;
}

export default CurrentStateCar;

const Component = styled.div`
  z-index: 26;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  width: 100%;
  padding: 6px 10px;
  background: #f7c73e;
  border-radius: 7px;

  ${media.mobile(css`
    font-size: 16px;
  `)}
`;
