import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  icon?: React.ReactNode;
  label: string;
  isMobile?: boolean;
  isLaptop?: boolean;
  className?: string;
}

const Badge = React.forwardRef<HTMLDivElement, Props>(
  ({ icon, label, isLaptop, isMobile, className }, ref) => {
    return (
      <Component
        isLaptop={isLaptop}
        isMobile={isMobile}
        className={className}
        ref={ref}
      >
        <Icon isLaptop={isLaptop} isMobile={isMobile}>
          {icon}
        </Icon>
        <Text>{label}</Text>
      </Component>
    );
  }
);

export default Badge;

export const Component = styled.div<{ isLaptop?: boolean; isMobile?: boolean }>`
  display: flex;
  align-items: center;
  background: ${colors.green200};
  border-radius: 28px;
  padding: 6px 10px;
  height: 32px;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.green100};

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      padding: 6px 8px;
      height: 24px;
      font-size: 10px;
      line-height: 12px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 4px 6px;
      height: 20px;
      font-size: 9px;
    `}
`;

const Icon = styled.span<{ isLaptop?: boolean; isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  svg {
    width: 20px;
    height: 20px;
  }

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      svg {
        width: 16px;
        height: 16px;
      }
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      svg {
        width: 12px;
        height: 12px;
      }
    `}
`;

export const Text = styled.span`
  font-weight: normal;
  white-space: nowrap;
`;
