import React from 'react';
import styled from 'styled-components';

import { CarStatusProps } from '@/components/CarStatus/types';
import CarStatus from '@/components/CarStatus';
import { colors } from '@/constants/theme';

function StockStatus({ carStatus }: CarStatusProps) {
  return (
    <Component>
      <CarStatus carStatus={carStatus} isSmall />
    </Component>
  );
}

export default StockStatus;

const Component = styled.div`
  padding: 7px;
  height: 25px;
  background: ${colors.white};
  border-radius: 5px;
`;
