import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import LocationIcon from '@/assets/svg/location.svg';
import { colors } from '@/constants/theme';

import Badge from '../Badge';

import Content from './components/Content';
import { BadgeLocationProps } from './types';

function BadgeLocation({
  label = 'Локация',
  locations,
  isLaptop,
  isMobile,
}: BadgeLocationProps) {
  return (
    <Component>
      <Tippy
        zIndex={9998}
        content={
          locations.length !== 0 ? <Content locations={locations} /> : null
        }
        placement="bottom"
      >
        <StyledBadge
          label={label}
          icon={<LocationIcon />}
          isLaptop={isLaptop}
          isMobile={isMobile}
        />
      </Tippy>
    </Component>
  );
}

export default BadgeLocation;

const StyledBadge = styled(Badge)`
  position: relative;
  color: ${colors.blue};
  background-color: ${colors.blue400};
`;

const Component = styled.div``;
