import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

import { ContentProps } from './types';

function Content({ video }: ContentProps) {
  return (
    <Component>
      <Iframe
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        src={video}
        allowFullScreen={true}
        width={250}
        height={140}
      />
    </Component>
  );
}

export default Content;

const Component = styled.div`
  width: 250px;
  height: 140px;
  overflow: hidden;

  background: ${colors.white};
  box-shadow: 0 4px 19px rgba(21, 27, 38, 0.13);
  border-radius: 4px;
`;

const Iframe = styled.iframe`
  border: none;
`;
