import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';

function ContentOnlyOnline() {
  return (
    <Component>
      <Span>
        Автомобиль доступен для покупки только в онлайн-автоцентре{' '}
        <BoldLink to={'https://atlantm.by/'}>atlantm.by</BoldLink>.
      </Span>
    </Component>
  );
}

export default ContentOnlyOnline;

const Component = styled.div`
  width: 290px;
  padding: 15px;

  background: ${colors.white};
  border: 0.5px solid ${colors.gray400};
  box-sizing: border-box;
  box-shadow: 0 4px 19px rgba(21, 27, 38, 0.13);
  border-radius: 8px;

  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  color: ${colors.black};
`;

const Span = styled.span``;

const BoldLink = styled(Link)`
  font-weight: 500;
  cursor: pointer;
`;
